import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-generic-page',
  templateUrl: './generic-page.component.html',
  styleUrls: ['./generic-page.component.less']
})
export class GenericPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
