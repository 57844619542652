import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { PopupService } from '../popup.service';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.less']
})
export class ToggleSwitchComponent implements OnInit {

  @Output() right = new EventEmitter<void>();
  @Output() left = new EventEmitter<void>();
  @Input() active: boolean;

  constructor(private popup: PopupService) { }

  ngOnInit() {
  }

  toggle() {
    this.active = !this.active;
    this.popup.close();
    if (this.active) {
      this.left.emit();
    } else {
      this.right.emit();
    }
  }

}
