
<div class='filters'>
  <h3>חיפוש לפי:</h3>
  <div class='columns'>
    <div class='column'>
      <app-filters-name-search></app-filters-name-search>
      <div class='year-selectors'>
        <app-filters-year-selector placeholder='משנה' field='year_founded'></app-filters-year-selector>
        <span>-</span>
        <app-filters-year-selector placeholder='עד שנה' field='year_closed'></app-filters-year-selector>
      </div>
      <app-filters-org-kind-selector></app-filters-org-kind-selector>
      <app-filters-area-selector></app-filters-area-selector>
    </div>
    <div class='column'>
      <div class='columns'>
        <div class='column'>
          <app-filters-subject-selector [disabled]='data.mode !== "subject"'></app-filters-subject-selector>
        </div>
        <div class='column'>
          <app-toggle-switch (left)='data.mode = "theme"' (right)='data.mode = "subject"'></app-toggle-switch>
        </div>
        <div class='column'>
          <app-filters-theme-selector [disabled]='data.mode !== "theme"'></app-filters-theme-selector>
        </div>
      </div>
      <div class='columns'>
        <div class='column'>
          <app-filters-activity-kind-selector></app-filters-activity-kind-selector>
        </div>
        <div class='column'></div>
        <div class='column'>
          <app-filters-target-audience-selector></app-filters-target-audience-selector>
        </div>
      </div>
      <div class='vertical-separator'></div>
      <div class='columns'>
        <div class='column'></div>
        <div class='column'></div>
        <div class='column'>
          <div class='search-button' (click)='data.search()' (keypress)='data.search()' (focus)='popup.close()' tabindex='0'>חיפוש</div>
          <div class='delete-button' (click)='data.clear()' (keypress)='data.clear()' (focus)='popup.close()' tabindex='0'>
            <img src='assets/img/trash-icon.svg'/>
            <span>ניקוי</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>