import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { DataService } from '../data.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-item-page',
  templateUrl: './item-page.component.html',
  styleUrls: ['./item-page.component.less']
})
export class ItemPageComponent implements OnInit {

  item: any = {};

  constructor(private activatedRoute: ActivatedRoute, private location: Location,
              private data: DataService) {
    this.activatedRoute.paramMap.pipe(
      map((params) => params.get('id'))
    ).subscribe((id) => {
      this.item = this.data.getById(id);
    });
  }

  ngOnInit() {
  }

  back() {
    this.location.back();
  }

}
