import { Component, OnInit } from '@angular/core';
import { PRIMARY_ACTIVITY_KIND } from '../data';
import { DataService } from '../data.service';

@Component({
  selector: 'app-filters-activity-kind-selector',
  templateUrl: './filters-activity-kind-selector.component.html',
  styleUrls: ['./filters-activity-kind-selector.component.less']
})
export class FiltersActivityKindSelectorComponent implements OnInit {

  options = PRIMARY_ACTIVITY_KIND;

  constructor(public data: DataService) { }

  ngOnInit() {
  }

}
