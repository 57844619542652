import { Component, OnInit } from '@angular/core';
import { PRIMARY_ACTIVITY_AREA } from '../data';
import { DataService } from '../data.service';

@Component({
  selector: 'app-filters-area-selector',
  templateUrl: './filters-area-selector.component.html',
  styleUrls: ['./filters-area-selector.component.less']
})
export class FiltersAreaSelectorComponent implements OnInit {

  options = PRIMARY_ACTIVITY_AREA;

  constructor(public data: DataService) { }

  ngOnInit() {
  }

}
