<app-generic-page>
  <div class='content'>
    <div class='back' (click)='back()'>חזרה לחיפוש</div>
    <div class='title-wrapper'>
      <div class='title'>
        <h1 *ngIf='item.name_common'>{{ item.name_common }} ({{ item.name}})</h1>
        <h1 *ngIf='!item.name_common'>{{ item.name}}</h1>
        <h2 *ngIf='item.name_ar'>{{ item.name_ar }}</h2>
        <h3 *ngIf='item.name_past'>
          <span>שם המנגנון בעבר: {{ item.name_past }}</span>
          <span *ngIf='item.name_change_year'>&nbsp;(שנת שינוי השם: {{item.name_change_year}})</span>
        </h3>
        <h3 *ngIf='item.name_replaced_by'>
          <span>החליף את: {{ item.name_replaced_by }}</span>
        </h3>
      </div>
    </div>

    <div class='main-props'>
      <div class='line'>
        <div class='item' *ngIf='item.year_founded'>
          <label>נוסד בשנת:&nbsp;</label><span>{{ item.year_founded }}</span>
        </div>
        <div class='item' *ngIf='!item.year_founded'>
          <span>נוסד לפני 1970</span>
        </div>
        <div class='item' *ngIf='item.is_active'>
          <span>{{ item.year_closed_text }}</span>
        </div>
        <div class='item' *ngIf='!item.is_active'>
          <label>נסגר בשנת:&nbsp;</label><span>{{ item.year_closed }}</span>
        </div>
        <div class='item' *ngIf='item.year_registered'>
          <label>שנת רישום כעמותה:&nbsp;</label><span>{{ item.year_registered }}</span>
        </div>
      </div>
      <div class='line'>
        <div class='item' *ngIf='item.display_organization_kind'>
          <label class='linky' [routerLink]='"/"' [fragment]='"organization_kind"'>סוג ארגון</label>:&nbsp;<span>{{ item.display_organization_kind }}</span>
        </div>
        <div class='item' *ngIf='item.primary_activity_area'>
          <label class='linky' [routerLink]='"/"' [fragment]='"activity_area"'>אזור פעילות עיקרי</label>:&nbsp;<span>{{ item.primary_activity_area }}</span>
        </div>
        <div class='item' *ngIf='item.official_address'>
          <label>כתובת:&nbsp;</label><span>{{ item.official_address }}</span>
        </div>
      </div>
    </div>

    <div class='prop-list' *ngIf='item.fields_of_activity && item.fields_of_activity.length'>
      <h1>תחומי פעילות <a class='qm' routerLink='/' fragment='fields_of_activity'></a></h1>
      <div class='prop-item' *ngFor='let el of item.fields_of_activity'>
        <span>{{el[0]}}</span>
        <ng-container *ngIf='el.length > 1'>
          <label>&nbsp;|&nbsp;תת תחום:&nbsp;</label>
          <span>{{el[1]}}</span>
        </ng-container>
      </div>
    </div>

    <div class='prop-list' *ngIf='item.activity_kinds && item.activity_kinds.length'>
      <h1>סוגי פעילות <a class='qm' routerLink='/' fragment='activity_kind'></a></h1>
      <div class='prop-item' *ngFor='let el of item.activity_kinds'>
        <span>{{el}}</span>
      </div>
    </div>

    <div class='prop-list' *ngIf='item.target_audiences && item.target_audiences.length'>
      <h1>קהלי יעד <a class='qm' routerLink='/' fragment='target_audiences'></a></h1>
      <div class='prop-item' *ngFor='let el of item.target_audiences'>
        <span>{{el}}</span>
      </div>
    </div>

    <div class='description' *ngIf='item.description || item.description_ar'>
      <h1 class='linky' [routerLink]='"/"' [fragment]='"description"'>תיאור</h1>
      <p>{{ item.description }}</p>
      <ng-container *ngIf='item.description_ar'>
        <br/>
        <p>{{ item.description_ar }}</p>
      </ng-container>
    </div>

    <div class='prop-list' *ngIf='item.sources && item.sources.length'>
      <h1>מקורות <a class='qm' routerLink='/' fragment='sources'></a></h1>
      <ng-container *ngFor='let el of item.sources; let idx = index'>
        <div class='source-item' *ngIf='el.title || el.url'>
          <span [innerHTML]='el.title || "קישור"'></span>
          <a [href]='el.url' target='_blank' *ngIf='el.url'><img src='assets/img/chain-icon.svg'></a>
          <span class='retrieved' *ngIf='idx === 0 && item.retrieved && item.retrieved["type{date}"]'>אוחזר בתאריך {{ item.retrieved['type{date}'] }}</span>
        </div>
      </ng-container>
    </div>

    <div class='meta-wrapper'>
      <div class='meta'>
        <div class='line'>דף זה כולל הפניות לקישורים חיצוניים שאינם באחריותנו. מצאת טעות? רוצה לספק מידע נוסף על ארגון זה? כתבו לנו: <a href='mailto:wpaisrael@gmail.com' target='_blank'>wpaisrael@gmail.com</a></div>
        <div class='line'>
          <div *ngIf='item.created'>
            <label>תאריך יצירת הערך&nbsp;</label>
            <span>{{ item.created.on }}</span>
            <label>נוצר על ידי&nbsp;</label>
            <span>{{ item.created.by }}</span>
          </div>
          <span>&nbsp;|&nbsp;</span>
          <div *ngIf='item.updated'>
            <label>תאריך עדכון אחרון&nbsp;</label>
            <span>{{ item.updated.on }}</span>
            <label>עודכן על ידי&nbsp;</label>
            <span>{{ item.updated.by }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-generic-page>