import { Component, OnInit } from '@angular/core';
import { PRIMARY_TARGET_AUDIENCE } from '../data';
import { DataService } from '../data.service';

@Component({
  selector: 'app-filters-target-audience-selector',
  templateUrl: './filters-target-audience-selector.component.html',
  styleUrls: ['./filters-target-audience-selector.component.less']
})
export class FiltersTargetAudienceSelectorComponent implements OnInit {

  options = PRIMARY_TARGET_AUDIENCE;

  constructor(public data: DataService) { }

  ngOnInit() {
  }

}
