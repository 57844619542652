import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-about-ar-page',
  templateUrl: './about-ar-page.component.html',
  styleUrls: ['./about-ar-page.component.less']
})
export class AboutArPageComponent implements OnInit {

  constructor(public data: DataService) { }

  ngOnInit() {
  }

}
