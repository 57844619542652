<div class='result'>
  <a class='title' [routerLink]='"/item/" + item.id' tabindex="0">
    <span class='he'>{{item.name_common || item.name }}</span>
    <ng-container *ngIf='item.name_ar'>
      <span class='ar'>{{item.name_ar}}</span>
    </ng-container>
  </a>
  <div class='years'>
    <div class='founded'>
      <label>נוסד: </label>
      <span>{{item.year_founded || "לפני 1970"}}</span>
    </div>
    <div class='closed'>
      <ng-container *ngIf='!item.is_active'>
        <label>נסגר: </label>
        <span>{{item.year_closed}}</span>
      </ng-container>
      <ng-container *ngIf='item.is_active'>
        <span>{{item.year_closed_text}}</span>
      </ng-container>
    </div>
  </div>
  <div class='separator'>
  </div>
  <div class='props'>
    <div class='main'>
      <label>ארגון </label>
      <span>{{ item.organization_kind[0].split(',')[0] }}</span>
      <label> הפועל ב</label>
      <span>{{ item.primary_activity_area }}</span>
      <label> בעיקר עם </label>
      <span>{{ item.target_audiences[0] }}</span>
    </div>
    <div class='secondary'>
      <label>תחום פעילות עיקרי: </label>
      <span>{{ item.primary_field_of_activity }}</span>
    </div>
  </div>
</div>