<span class='item' *ngFor='let item of data' (click)='select(valueOf(item))'
      (keypress)='$event.key === "Enter"? select(valueOf(item)) : null' [tabindex]="active? 0 : -1" 
      [innerHTML]='showOf(item)' [style.text-align]='textAlign'>
</span>
<span class='item' *ngIf='data.length === 0 && !empty' [style.text-align]='textAlign'  tabindex="-1" >
      לא נמצאו תוצאות
</span>
<span class='item' *ngIf='data.length === 0 && empty' [style.text-align]='textAlign'  tabindex="-1" >
      הקלידו שם של ארגון
</span>
