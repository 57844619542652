import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InteractiveGraphComponent } from './interactive-graph/interactive-graph.component';
import { GenericPageComponent } from './generic-page/generic-page.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MainPageComponent } from './main-page/main-page.component';
import { NavigationComponent } from './navigation/navigation.component';
import { AboutArPageComponent } from './about-ar-page/about-ar-page.component';
import { AboutEnPageComponent } from './about-en-page/about-en-page.component';
import { DatasetPageComponent } from './dataset-page/dataset-page.component';
import { FiltersComponent } from './filters/filters.component';
import { GenericInputComponent } from './generic-input/generic-input.component';
import { FiltersNameSearchComponent } from './filters-name-search/filters-name-search.component';
import { FiltersYearSelectorComponent } from './filters-year-selector/filters-year-selector.component';
import { FiltersOrgKindSelectorComponent } from './filters-org-kind-selector/filters-org-kind-selector.component';
import { FiltersAreaSelectorComponent } from './filters-area-selector/filters-area-selector.component';
import { FiltersSubjectSelectorComponent } from './filters-subject-selector/filters-subject-selector.component';
import { FiltersThemeSelectorComponent } from './filters-theme-selector/filters-theme-selector.component';
import { FiltersActivityKindSelectorComponent } from './filters-activity-kind-selector/filters-activity-kind-selector.component';
import { FiltersTargetAudienceSelectorComponent } from './filters-target-audience-selector/filters-target-audience-selector.component';
import { SearchResultComponent } from './search-result/search-result.component';
import { ItemPageComponent } from './item-page/item-page.component';
import { Router, Scroll, Event } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';
import { FiltersPlaceholderDropdownComponent } from './filters-placeholder-dropdown/filters-placeholder-dropdown.component';
import { FiltersDropdownListSelectorComponent } from './filters-dropdown-list-selector/filters-dropdown-list-selector.component';
import { FiltersPlaceholderInputComponent } from './filters-placeholder-input/filters-placeholder-input.component';
import { FiltersPlaceholderSearchComponent } from './filters-placeholder-search/filters-placeholder-search.component';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { FiltersDropdownThemeSelectorComponent } from './filters-dropdown-theme-selector/filters-dropdown-theme-selector.component';

@NgModule({
  declarations: [
    AppComponent,
    InteractiveGraphComponent,
    GenericPageComponent,
    HeaderComponent,
    FooterComponent,
    MainPageComponent,
    NavigationComponent,
    AboutArPageComponent,
    AboutEnPageComponent,
    DatasetPageComponent,
    FiltersComponent,
    GenericInputComponent,
    FiltersNameSearchComponent,
    FiltersYearSelectorComponent,
    FiltersOrgKindSelectorComponent,
    FiltersAreaSelectorComponent,
    FiltersSubjectSelectorComponent,
    FiltersThemeSelectorComponent,
    FiltersActivityKindSelectorComponent,
    FiltersTargetAudienceSelectorComponent,
    SearchResultComponent,
    ItemPageComponent,
    FiltersPlaceholderDropdownComponent,
    FiltersDropdownListSelectorComponent,
    FiltersPlaceholderInputComponent,
    FiltersPlaceholderSearchComponent,
    ToggleSwitchComponent,
    FiltersDropdownThemeSelectorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    router.events.pipe(
      filter((e: Event): e is Scroll => e instanceof Scroll)
    ).subscribe(e => {
      if (e.position) {
        // backward navigation
        setTimeout(() => {
          viewportScroller.scrollToPosition(e.position);
        }, 0);
      } else if (e.anchor) {
        // anchor navigation
        setTimeout(() => {
          viewportScroller.scrollToAnchor(e.anchor);
        }, 100);
      } else {
        // forward navigation
        viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
}
