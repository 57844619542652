<div class='footer'>
  <div class='column'>
    <div class='logo'>
    </div>
    <div class='text'>
      <div class='line'>
        <h1>התכנית ללימודי מגדר<span class='sep'></span>הפקולטה למדעי החברה והרוח</h1>
        <h1>Gender Studies Program<span class='sep'></span>Ben Gurion University of the Negev</h1>
      </div>
      <div class='line'>
        <h2><a href='assets/terms.pdf'>תנאי שימוש באתר</a><span class='sep'></span><a href='https://in.bgu.ac.il/Pages/accessibility.aspx'>הצהרת נגישות</a><span class='sep'></span><img src='assets/img/email.svg'> ת.ד. 653, באר-שבע 8410501</h2>
        <h2>Be’er-Sheva, Israel<span class='sep'></span>e-mail: <a href='mailto:wpaisrael@gmail.com' target='_blank'>wpaisrael@gmail.com</a></h2>
      </div>
      <div class='dev'>פיתוח האתר: <a href='https://whiletrue.industries' target='_blank'>אדם קריב</a></div>
    </div>
  </div>
</div>