<div class='container'>
    <div class='placeholder-content' [class.blue]='blue' (click)='toggle()' [class.visible]='placeholderVisible' [class.disabled]='disabled' tabindex="-1">
        <ng-content select='.placeholder'></ng-content>
    </div>
    <input type='text' [class.visible]='inputVisible && !disabled && input'
           (change)='inputDone()' (keyup)='keypress($event)' (focus)='focus()'
           #inputEl [tabindex]='!disabled && input ? 0 : -1'
           [style.text-align]='inputAlign' [class.blue]='blue'/>
    <div class='popup-content' [class.visible]='popupOpen && popup && !disabled' (focus)='popupOpen ? null : toggle(true)'
        [tabindex]="popup? 0: -1">
        <ng-content select='.popup'></ng-content>
    </div>
</div>