import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.less']
})
export class MainPageComponent implements OnInit {

  tab = 'about';

  constructor(private activatedRoute: ActivatedRoute, public data: DataService) {
    this.activatedRoute.fragment.pipe(first()).subscribe((fragment) => {
      if (fragment) {
        this.tab = 'coding';
      }
    });
  }

  ngOnInit() {
  }

}
