<div class='graph'>
<div class='container' #container></div>
<h4 class='x label' [class.closed]='closed'>שנים</h4>
<h4 class='y label'>כמות מנגנונים פעילים</h4>
</div>
<div class='controls' [class.open]='!closed'>
  <div class='switcher' *ngIf='!closed'>
    <div class='switcher-item' [class.active]='kind === "total"'>
      <input type="radio" id='total' name='series' value='total' (click)='switchSeries("total")' [checked]='kind==="total"'/><label for='total'>הכל</label>
    </div>
    <div class='switcher-item' [class.active]='kind === "primary_organization_kind"' *ngIf='!data.filters.primary_organization_kind'>
      <input type="radio" id='primary_organization_kind' name='series' value='primary_organization_kind'
             (click)='switchSeries("primary_organization_kind")' [checked]='kind==="primary_organization_kind"'/><label for='primary_organization_kind'>סוג ארגון</label>
    </div>
    <div class='switcher-item' [class.active]='kind === "primary_field_of_activity"' *ngIf='show_primary_field_of_activity()'>
      <input type="radio" id='primary_field_of_activity' name='series' value='primary_field_of_activity'
            (click)='switchSeries("primary_field_of_activity")' [checked]='kind==="primary_field_of_activity"'/><label for='primary_field_of_activity'>תחום עיקרי</label>
    </div>
    <div class='switcher-item' [class.active]='kind === "primary_sub_field_of_activity"' *ngIf='show_primary_sub_field_of_activity()'>
      <input type="radio" id='primary_sub_field_of_activity' name='series' value='primary_sub_field_of_activity'
             (click)='switchSeries("primary_sub_field_of_activity")' [checked]='kind==="primary_sub_field_of_activity"'/><label for='primary_sub_field_of_activity'>תת-תחום</label>
    </div>
    <div class='switcher-item' [class.active]='kind === "primary_activity_kind"' *ngIf='!data.filters.activity_kinds'>
      <input type="radio" id='primary_activity_kind' name='series' value='primary_activity_kind'
             (click)='switchSeries("primary_activity_kind")' [checked]='kind==="primary_activity_kind"'/><label for='primary_activity_kind'>סוג פעילות עיקרי בשנת פעילות ראשונה</label>
    </div>
    <div class='switcher-item' [class.active]='kind === "primary_target_audience"' *ngIf='!data.filters.primary_target_audience'>
      <input type="radio" id='primary_target_audience' name='series' value='primary_target_audience'
             (click)='switchSeries("primary_target_audience")' [checked]='kind==="primary_target_audience"'/><label for='primary_target_audience'>קהל יעד עיקרי</label>
    </div>
    <div class='switcher-item' [class.active]='kind === "primary_activity_area"' *ngIf='!data.filters.primary_activity_area'>
      <input type="radio" id='primary_activity_area' name='series' value='primary_activity_area'
             (click)='switchSeries("primary_activity_area")' [checked]='kind==="primary_activity_area"'/><label for='primary_activity_area'>אזור פעילות עיקרי בשנת הפעילות האחרונה</label>
    </div>
  </div> 
  <div class='open-control' (click)='closed = !closed' *ngIf='!alwaysOpen'>
    <img src='assets/img/chevron-down.svg'/>
    <span *ngIf='closed'>להרחבה וסינון נוסף</span>
    <span *ngIf='!closed'>לתצוגה הכללית</span>
  </div>
</div>