import { Component, OnInit } from '@angular/core';
import { RECORDS } from '../data';
import { Router } from '@angular/router';

@Component({
  selector: 'app-filters-name-search',
  templateUrl: './filters-name-search.component.html',
  styleUrls: ['./filters-name-search.component.less']
})
export class FiltersNameSearchComponent implements OnInit {

  filtered: any[];
  empty = true;

  constructor(private router: Router) { }

  ngOnInit() {
    this.filter('');
  }

  clean(value: string) {
    return value.replace(/["״.]/g, '.').toLowerCase();
  }

  filter(value) {
    this.filtered = [];
    const cleanedValue = value ? this.clean(value) : null;
    this.empty = !cleanedValue;
    if (this.empty) {
      return;
    }
    for (const record of RECORDS) {
      for (const field of [record.name_common, record.name]) {
        if (!field) {
          continue;
        }
        const searchField = this.clean(field);
        if (searchField.length > 2 && searchField.indexOf(cleanedValue) !== -1) {
          const location = searchField.indexOf(cleanedValue);
          let highlighted = field;
          if (location >= 0) {
            highlighted = field.slice(0, location) + '<b>' + field.slice(location, location + value.length) +
                          '</b>' + field.slice(location + value.length);
          }
          const founded = record.year_founded ? `נוסד ב-${record.year_founded}` : 'נוסד לפני 1970';
          const dates = `${founded} - ` +
                        (record.is_active ? record.year_closed_text : `נסגר ב-${record.year_closed}`);
          this.filtered.push({
            show: `${highlighted}<br/><small>${dates}</small>`,
            value: record.id
          });
          break;
        }
      }
    }
  }

  select(value) {
    this.router.navigateByUrl('item/' + value);
  }
}
