import { Component, OnInit } from '@angular/core';
import { PRIMARY_ORGANIZATION_KIND } from '../data';
import { DataService } from '../data.service';

@Component({
  selector: 'app-filters-org-kind-selector',
  templateUrl: './filters-org-kind-selector.component.html',
  styleUrls: ['./filters-org-kind-selector.component.less']
})
export class FiltersOrgKindSelectorComponent implements OnInit {

  options = PRIMARY_ORGANIZATION_KIND;

  constructor(public data: DataService) { }

  ngOnInit() {
  }

}
