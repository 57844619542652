import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input, OnChanges } from '@angular/core';
import { DataService } from '../data.service';

import * as d3 from 'd3';
import { Observable } from 'rxjs';
import { MAX_YEAR } from '../data';

@Component({
  selector: 'app-interactive-graph',
  templateUrl: './interactive-graph.component.html',
  styleUrls: ['./interactive-graph.component.less']
})
export class InteractiveGraphComponent implements OnInit, AfterViewInit, OnChanges {

  margin = {top: 20, right: 30, bottom: 35, left: 30};
  legendHeight = 100;
  width = 1200;
  closedHeight = 200;
  height = 500;
  kind = 'total';
  records = [];
  total = ['סה״כ'];
  processed: any = {};
  _closed = true;

  @ViewChild('container', { static: true }) container: ElementRef;
  @Input() recordSource: Observable<any[]>;
  @Input() alwaysOpen = false;

  constructor(public data: DataService) {
  }

  ngOnInit() {
    this.recordSource.subscribe((records) => {
      if (records === null) {
        this.closed = true;
        return;
      }
      this.records = records;
      this.show_primary_sub_field_of_activity();
      this.open_if_needed();
      this.processData();
    });
    if (this.alwaysOpen) {
      this._closed = false;
    }
  }

  ngAfterViewInit() {
    this.refresh();
  }

  ngOnChanges() {
    this.refresh();
  }

  switchSeries(kind) {
    this.kind = kind;
    this.refresh();
  }

  set closed(value) {
    this._closed = value;
    if (this._closed) {
      this.kind = 'total';
    }
    this.refresh();
  }

  get closed() {
    return this._closed;
  }

  processData() {
    this.processed = {};
    for (const kind of ['total', 'primary_organization_kind', 'primary_field_of_activity',
                        'primary_activity_kind', 'primary_target_audience', 'primary_activity_area',
                        'primary_sub_field_of_activity']) {
      this.processed[kind] = this.processDataSeries(kind);
    }
  }

  processDataSeries(kind) {
    const seriesValues: string[] = this.data[kind] || this.total;
    const seriesValuesCounts = {};
    const graph = {};
    for (let year = 1970; year < MAX_YEAR + 1 ; year++ ) {
      graph[year] = {year: year};
    }
    for (const record of this.records) {
      const min_year = record['year_founded'] || 1970;
      const max_year = record['year_closed'];
      const record_value = kind === 'total' ? this.total[0] : record[kind];
      seriesValuesCounts[record_value] = true;
      for (let year = min_year ; year <= max_year ; year ++ ) {
        graph[year][record_value] = (graph[year][record_value] || 0) + 1;
      }
    }
    const actualSeriesValues = seriesValues.filter((x) => seriesValuesCounts[x]);
    for (let year = 1970; year < 2019 ; year++ ) {
      for (const value of actualSeriesValues) {
        graph[year][value] = graph[year][value] || 0;
      }
    }
    for (let year = 1970; year < MAX_YEAR + 1 ; year++ ) {
      if ((this.data.filters.year_founded && year < this.data.filters.year_founded) ||
          (this.data.filters.year_closed && year > this.data.filters.year_closed)) {
          delete graph[year];
      }
    }
    return {
      graph: Object.values(graph),
      seriesValues: actualSeriesValues
    };
  }

  refresh() {
    const processed = this.processed[this.kind];
    if (!processed) {
      return;
    }
    const data: any[] = this.processed[this.kind].graph;
    const series: any[] = this.processed[this.kind].seriesValues;

    if (!data.length || !series.length) {
      return;
    }

    if (!this.container.nativeElement) { return; }

    this.width = (<HTMLElement>this.container.nativeElement).clientWidth;
    const width = this.width - this.margin.left - this.margin.right;
    const fullHeight = (this.closed ? this.closedHeight : this.height);
    const height = fullHeight - this.margin.top - this.margin.bottom - (this.closed ? 0 : this.legendHeight);

    this.container.nativeElement.innerHTML = '';
    const _svg = d3.select(this.container.nativeElement)
      .append('svg')
      .attr('width', this.width)
      .attr('height', fullHeight);
    const svg = _svg
      .append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');


    const dataset = d3.stack().keys(series)(data);

    const x = d3.scaleBand()
      .domain(data.map((d: any) => d.year))
      .range([10, width - 10])
      .padding(0.02);

    const y = d3.scaleLinear()
      .domain([0, d3.max(dataset, (d) => d3.max(d, (d1) => d1[1]))])
      .range([height, 0]);

    const yAxis = d3.axisLeft(y)
      .ticks(5)
      .tickSize(-width, 0, 0);

    const xAxis = d3.axisBottom(x)
      .tickFormat((d) => {
        if (d !== 1970 && d !== MAX_YEAR) {
          return '`' + d.toString().slice(2);
        }
        return d.toString();
      });

    const colorScale = [
      '#fbf634',
      '#ffc900',
      '#ff9416',
      '#ff4e47',
      '#ff0073',
      '#ff00a5',
      '#cf00d7',
      '#342aff',
      '#1bff35',
      '#c5b500',
      '#e66100',
      '#c7004b',
      '#740c68',
      '#260c74',
      '#0c742d',
      '#e09fac',
      '#00ffdd',
    ].slice(0, series.length);

    const steps = d3.scaleLinear()
      .domain([0, colorScale.length - 1])
      .range([0, series.length - 1]);
    const domain = Array.from({length: colorScale.length}, (_, i) => steps(i));
    const _colors = d3.scaleLinear()
      .interpolate(d3.interpolateHcl)
      .domain(domain)
      .range(colorScale);

    const colors = (i) => {
      if (series.length === 1) {
        return '#3C95B4';
      } else {
        return _colors(i);
      }
    };

    svg.append('g')
      .attr('class', 'y axis')
      .call(yAxis);

    svg.append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis);

    const groups = svg.selectAll('g.cost')
      .data((d, i) => dataset.map((xx) => Object.assign(xx, {sss: i})))
      .enter().append('g')
      .attr('class', 'cost')
      .style('fill', (d, i) => colors(i));

    const rect = groups.selectAll('rect')
      .data((d) => d)
      .enter()
      .append('rect')
      .attr('x', (d, i) => x(data[i].year))
      .attr('y', (d) => y(d[1]))
      .attr('height', (d) => y(d[0]) - y(d[1]))
      .attr('width', x.bandwidth());
    rect.append('title').text(function(d, i) {
      const subject = d3.select(this.parentNode.parentNode).datum().key;
      const amount = d[1] - d[0];
      if (amount > 1) {
        return `${subject}: ${amount} ארגונים`;
      } else {
        return `${subject}: ארגון אחד`;
      }
    });

    if (this.kind !== 'total' && !this.closed) {
      const z = 5;
      const legend = _svg.selectAll('.legend')
          .data(series)
          .enter().append('g')
          .attr('class', 'legend')
          .attr('transform', (d, i) => {
            const _x = width / z * (z - 1 - i % z) + this.margin.left - 10;
            const _y = fullHeight - this.margin.bottom - this.legendHeight + Math.floor(i / z) * 25 + 40;
            return 'translate(' + _x + ','  + _y + ')';
          });
      legend.append('rect')
          .attr('x', width / z - 18)
          .attr('width', 18)
          .attr('height', 18)
          .style('fill', (d, i) => colors(i));

      legend.append('text')
        .attr('x', width / z - 22)
        .attr('y', 9)
        .attr('dy', '.35em')
        .style('text-anchor', 'start')
        .text((d) => d);
    }

  }

  open_if_needed() {
    let distinct_themes = null;
    if (this.data.filters.subject && this.data['primary_sub_field_of_activity'].length > 0) {
      this._closed = false;
      this.kind = 'primary_sub_field_of_activity';
      return;
    }
    if (this.data.filters.theme) {
      for (const item of this.data.filters.theme) {
        const primary = item[0];
        if (item.length !== 1) {
          return;
        }
        if (distinct_themes) {
          this._closed = false;
          this.kind = 'primary_field_of_activity';
          return;
        }
        distinct_themes = true;
      }
    }
    if (distinct_themes && this.data['primary_sub_field_of_activity'].length > 0) {
      this._closed = false;
      this.kind = 'primary_sub_field_of_activity';
    }
  }

  show_primary_field_of_activity() {
    const distinct_themes = [];
    if (this.data.filters.subject) {
      return false;
    }
    if (this.data.filters.theme) {
      for (const item of this.data.filters.theme) {
        const primary = item[0];
        if (distinct_themes.indexOf(primary) < 0) {
          distinct_themes.push(primary);
        }
      }
    }
    return distinct_themes.length !== 1;
  }


  show_primary_sub_field_of_activity() {
    let distinct_themes = null;
    this.data.process_sub_fields_of_activity(null);
    if (this.data.filters.subject) {
      distinct_themes = this.data.filters.subject;
    }
    if (this.data.filters.theme) {
      for (const item of this.data.filters.theme) {
        const primary = item[0];
        if (distinct_themes && distinct_themes !== item[0]) {
          return false;
        }
        distinct_themes = item[0];
      }
    }
    if (distinct_themes) {
      this.data.process_sub_fields_of_activity(distinct_themes);
      return this.data['primary_sub_field_of_activity'].length > 0;
    } else {
      return false;
    }
  }
}
