<div class='header'>
  <div class='column'>
    <div class='bgu-logo'>
    </div>
    <div class='site-logo'>
      <a href='/' class='logo'>
      </a>
      <div class='text'>
        <h1>מארג</h1>
        <h2>מאגר מנגנונים ומוסדות לקידום נשים,
            פמיניזם ומגדר בישראל 2018-1970</h2>
      </div>
    </div>
  </div>
</div>