import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DataService } from '../data.service';
import { FIELDS_HIERARCHY } from '../data';
import { PopupService } from '../popup.service';

@Component({
  selector: 'app-filters-dropdown-theme-selector',
  templateUrl: './filters-dropdown-theme-selector.component.html',
  styleUrls: ['./filters-dropdown-theme-selector.component.less']
})
export class FiltersDropdownThemeSelectorComponent implements OnInit {

  @Input() slug = 'xxx';
  @Output() selected = new EventEmitter<any>();
  active = false;
  visible_items = [];
  items = {};

  constructor(private data: DataService, private popup: PopupService) {
    this.data.update.subscribe(() => {
      this.refreshVisible();
    });
    this.popup.popup.subscribe((open) => {
      this.active = open === this.slug;
    });
  }

  ngOnInit() {
    this.refreshVisible();
  }

  analyzeSelection() {
    this.data.filters.theme = this.data.filters.theme || [];
    const selection = this.data.filters.theme;
    if (selection.length > 0) {
      const mains = Array.from(new Set(selection.map((x) => x[0])));
      const subs = Array.from(new Set(selection.filter((x) => x.length > 1).map((x) => x[1])));
      const main = mains.length === 1 ? mains[0] : null;
      return {mains, subs, main};
    }
    return {mains: [], subs: [], main: null};
  }

  getItem(checked, kind, value, main?) {
    const key = `${kind}/${value}/${main}`;
    if (!this.items[key]) {
      const item: any = {kind, value};
      if (main) {
        item.main = main;
      }
      this.items[key] = item;
    }
    const ret = this.items[key];
    ret.checked = checked;
    return ret;
  }

  refreshVisible() {
    const analysis = this.analyzeSelection();
    this.selected.next(analysis);
    this.visible_items.splice(0, this.visible_items.length);
    for (const field of FIELDS_HIERARCHY) {
      this.visible_items.push(this.getItem(
        analysis.mains.indexOf(field.main) !== -1,
        'main', field.main
      ));
      if (field.main === analysis.main) {
        for (const field2 of field.sub) {
          this.visible_items.push(this.getItem(
            analysis.subs.indexOf(field2) !== -1,
            'sub', field2, field.main
          ));
        }
        this.visible_items[this.visible_items.length - 1].last = true;
      }
    }
  }

  select(item) {
    let analysis = this.analyzeSelection();
    if (item.kind === 'main') {
      if (analysis.mains.indexOf(item.value) !== -1) {
        this.data.filters.theme = this.data.filters.theme.filter((x) => x[0] !== item.value);
      } else {
        this.data.filters.theme.push([item.value]);
        this.data.filters.theme = this.data.filters.theme.slice();
      }
    } else if (item.kind === 'sub') {
      if (analysis.subs.indexOf(item.value) !== -1) {
        this.data.filters.theme = this.data.filters.theme.filter((x) => x.length < 2 || x[1] !== item.value);
      } else {
        this.data.filters.theme.push([item.main, item.value]);
        this.data.filters.theme = this.data.filters.theme.slice();
      }
    }
    analysis = this.analyzeSelection();
    if (!analysis.main) {
      this.data.filters.theme = this.data.filters.theme.filter((i) => i.length === 1);
      analysis.subs = [];
    }
    this.data.filters.theme.forEach((filter) => {
      filter.primary_only = !analysis.main;
      filter.main_only = analysis.subs.length === 0;
    });
    setTimeout(() => {
      this.refreshVisible();
    }, 0);
  }

}
