import { Injectable } from '@angular/core';
import { RECORDS, PRIMARY_ORGANIZATION_KIND, PRIMARY_ACTIVITY_KIND,
  PRIMARY_ACTIVITY_AREA, PRIMARY_FIELD_OF_ACTIVITY, PRIMARY_TARGET_AUDIENCE } from './data';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public records = new BehaviorSubject<any[]>(RECORDS);
  public filtered = new BehaviorSubject<any[]>(null);
  public update = new Subject<void>();

  private primary_organization_kind = PRIMARY_ORGANIZATION_KIND;
  private primary_activity_kind = PRIMARY_ACTIVITY_KIND;
  private primary_field_of_activity = PRIMARY_FIELD_OF_ACTIVITY;
  private primary_activity_area = PRIMARY_ACTIVITY_AREA;
  private primary_target_audience = PRIMARY_TARGET_AUDIENCE;
  private primary_sub_field_of_activity = [];

  private _sort_by = 'year_founded';
  private _mode = 'subject';

  public filters: any;
  public has_results = false;

  constructor() {
    this.create_filters();
  }

  create_filters() {
    this.filters = Object.create({});
    const that = this;
    for (const key of ['primary_target_audience', 'activity_kinds', 'year_founded', 'year_closed',
                       'theme', 'subject', 'primary_organization_kind', 'primary_activity_area']) {
      Object.defineProperty(this.filters, '_' + key, {
        enumerable: false,
        writable: true,
      });
      Object.defineProperty(this.filters, key, {
        enumerable: true,
        set(value) {
          const current = this['_' + key];
          this['_' + key] = value;
          if (current !== value) {
            that.clear_results();
          }
        },
        get() {
          return this['_' + key];
        }
      });
    }
  }

  getById(id) {
    for (const record of RECORDS) {
      if (record.id === id) {
        return record;
      }
    }
    return {};
  }

  test_subject(subject) {
    return (row) => {
      const activity_fields = row.fields_of_activity;
      if (!activity_fields) {
        return false;
      }
      for (const item of activity_fields) {
        if (subject === item[0]) {
          return true;
        }
      }
    };
  }

  test_theme(theme) {
    return (row) => {
      for (const filter of theme) {
        let fields = row.fields_of_activity;
        if (filter.primary_only) {
          fields = fields.slice(0, 1);
        }
        for (const field of fields) {
          if (filter.main_only) {
            if (field[0] === filter[0]) {
              return true;
            }
          } else {
            if (filter.length > 1 && field[0] === filter[0] && field[1] === filter[1]) {
              return true;
            }
          }
        }
      }
    };
  }

  test_field(field, value) {
    return (row) => {
      const test = row[field];
      if (Array.isArray(test)) {
        return test.indexOf(value) !== -1;
      }
      return test === value;
    };
  }

  search() {
    const funcs = [];
    if (this.filters.year_founded && this.filters.year_closed && this.filters.year_founded > this.filters.year_closed) {
      const temp = this.filters.year_founded;
      this.filters.year_founded = this.filters.year_closed;
      this.filters.year_closed = temp;
    }
    for (const key of Object.keys(this.filters)) {
      const value = this.filters[key];
      if (!value || key[0] === '_') {
        continue;
      }
      // console.log('FILTER', key, value);
      if (key === 'subject') {
        if (this.mode === 'subject' && value) {
          funcs.push(this.test_subject(value));
        }
      } else if (key === 'theme') {
        if (this.mode === 'theme' && value && value.length > 0) {
          // console.log('THEME search', value);
          funcs.push(this.test_theme(value));
        }
      } else if (key === 'year_founded') {
        funcs.push((row) => row.is_active || (row.year_closed >= parseInt(value, 10)));
      } else if (key === 'year_closed') {
        funcs.push((row) => (row.year_founded || 1) <= parseInt(value, 10));
      } else {
        funcs.push(this.test_field(key, value));
      }
    }
    // console.log('funcs', funcs);
    const filtered = [];
    for (const record of RECORDS) {
      let matched = false;
      for (const func of funcs) {
        matched = func(record);
        if (!matched) {
          break;
        }
      }
      if (matched) {
        filtered.push(record);
      }
    }
    if (this._sort_by) {
      filtered.sort((a, b) => this.sort_key(a) - this.sort_key(b));
    }
    this.filtered.next(filtered);
    this.has_results = true;
  }

  clear_results() {
    // console.log('CLEAR RESULTS');
    this.has_results = false;
    this._sort_by = null;
    this.filtered.next(null);
  }

  clear() {
    this.create_filters();
    this.clear_results();
    this.update.next();
  }

  sort_key(r) {
    if (this._sort_by === 'year_founded') {
      return r.year_founded || 1969;
    }
  }

  set sort_by(value) {
    this._sort_by = value;
    this.search();
  }

  set mode(value) {
    this._mode = value;
    this.filters.theme = [];
    this.filters.subject = null;
    this.update.next();
    this.clear_results();
  }

  get mode() {
    return this._mode;
  }

  process_sub_fields_of_activity(field) {
    if (!field) {
      this.primary_sub_field_of_activity = [];
      return;
    }
    if (this.primary_sub_field_of_activity.length > 0) {
      return;
    }
    for (const record of this.filtered.getValue()) {
      let found = false;
      for (const item of record.fields_of_activity) {
        if (item.length > 1 && item[0] === field) {
          if (this.primary_sub_field_of_activity.indexOf(item[1]) === -1) {
            this.primary_sub_field_of_activity.push(item[1]);
          }
          record.primary_sub_field_of_activity = item[1];
          found = true;
          break;
        }
      }
    }
    this.primary_sub_field_of_activity.sort();
  }
}
