import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { fromEvent } from 'rxjs';
import { first } from 'rxjs/operators';
import { PopupService } from '../popup.service';

@Component({
  selector: 'app-generic-input',
  templateUrl: './generic-input.component.html',
  styleUrls: ['./generic-input.component.less']
})
export class GenericInputComponent implements OnInit {

  @Input() input = false;
  @Input() popup = true;
  @Input() blue = false;
  @Input() inputAlign = 'center';
  @Input() disabled = false;
  @Input() slug;

  @ViewChild('inputEl') inputEl: ElementRef;

  @Output() selected = new EventEmitter<string>();
  @Output() typing = new EventEmitter<string>();

  stateOpen = false;
  popupOpen = false;
  placeholderVisible = true;
  inputVisible = false;

  constructor(private popupService: PopupService) {
    this.popupService.popup.subscribe((open) => {
      if (open === this.slug) {
        this.toggle(true, true);
      } else {
        if (this.inputEl) {
          const el = this.inputEl.nativeElement;
          if (el) {
            el.value = '';
          }
        }
        this.toggle(false, true);
      }
    });
  }

  ngOnInit() {
  }

  focus() {
    if (!this.stateOpen) {
      this.toggle(true);
    }
  }

  toggle(value?, dontNotify?) {
    if (value !== undefined) {
      this.stateOpen = !value;
    }
    if (!this.stateOpen && !this.disabled) {
      this.stateOpen = true;
      if (this.popup) {
        this.popupOpen = true;
      }
      if (this.input) {
        this.placeholderVisible = false;
        this.inputVisible = true;
        this.focusInput();
      }
      setTimeout(() => {
        fromEvent(window, 'click').pipe(first()).subscribe(() => {
          this.toggle(false);
        });
      }, 0);
      if (!dontNotify) {
        this.popupService.open(this.slug);
      }
    } else {
      this.stateOpen = false;
      this.popupOpen = false;
      this.placeholderVisible = true;
      this.inputVisible = false;
      if (!dontNotify) {
        this.popupService.close(this.slug);
      }
    }
  }

  focusInput() {
    if (this.inputEl) {
      const el = this.inputEl.nativeElement;
      if (el) {
        el.focus();
      }
    }
  }

  inputDone() {
    const el = this.inputEl.nativeElement;
    if (el) {
      this.selected.next(el.value);
    }
    if (this.stateOpen && !this.popupOpen) {
      this.toggle(false);
    }
  }

  keypress(event) {
    this.typing.next(event.target.value);
  }

}
