import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-filters-year-selector',
  templateUrl: './filters-year-selector.component.html',
  styleUrls: ['./filters-year-selector.component.less']
})
export class FiltersYearSelectorComponent implements OnInit {

  @Input() placeholder: string;
  @Input() field: string;

  constructor(public data: DataService) { }

  ngOnInit() {
  }

}
