import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-dataset-page',
  templateUrl: './dataset-page.component.html',
  styleUrls: ['./dataset-page.component.less']
})
export class DatasetPageComponent implements OnInit {

  public records: any[];

  constructor(public data: DataService) {
    this.records = data.filtered.getValue();
    data.filtered.subscribe((records) => {
      this.records = records;
    });
  }

  ngOnInit() {
  }

}
