import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../data.service';
import { PopupService } from '../popup.service';

@Component({
  selector: 'app-filters-theme-selector',
  templateUrl: './filters-theme-selector.component.html',
  styleUrls: ['./filters-theme-selector.component.less']
})
export class FiltersThemeSelectorComponent implements OnInit {

  @Input() disabled = false;

  placeholder = null;

  constructor(private data: DataService, private popup: PopupService) {
  }

  ngOnInit() {
  }

  selected(analysis) {
    setTimeout(() => {
      this.updatePlaceholder(analysis);
    }, 0);
  }

  clear(event) {
    this.data.filters.theme = [];
    this.data.update.next(null);
    this.popup.close();
    event.stopPropagation();
  }

  updatePlaceholder(analysis) {
    this.placeholder = null;
    if (analysis.main) {
      if (analysis.subs.length === 0) {
        this.placeholder = `${analysis.main}`;
      } else if (analysis.subs.length === 1) {
        this.placeholder = `${analysis.main} / ${analysis.subs[0]}`;
      } else {
        this.placeholder = `${analysis.main} / ${analysis.subs.length} תתי-תחומים`;
      }
    } else if (analysis.mains.length > 1) {
      this.placeholder = `השוואת<span class='qm' title='בהשוואת שני תחומי פעילות או יותר, יוצגו מנגנונים שאלו תחומי הפעילות הראשיים שלהם'></span> ` +
        `${analysis.mains.length} תחומי פעילות`;
    }
  }
}
