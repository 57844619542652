import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { PopupService } from '../popup.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.less']
})
export class FiltersComponent implements OnInit {

  constructor(public data: DataService, public popup: PopupService) { }

  ngOnInit() {
  }

}
