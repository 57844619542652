import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainPageComponent } from './main-page/main-page.component';
import { AboutArPageComponent } from './about-ar-page/about-ar-page.component';
import { AboutEnPageComponent } from './about-en-page/about-en-page.component';
import { DatasetPageComponent } from './dataset-page/dataset-page.component';
import { ItemPageComponent } from './item-page/item-page.component';

const routes: Routes = [
  {
    path: 'en', component: AboutEnPageComponent, data: {section: 'en'},
  },
  {
    path: 'ar', component: AboutArPageComponent, data: {section: 'ar'},
  },
  {
    path: 'database', component: DatasetPageComponent, data: {section: 'database'},
  },
  {
    path: 'item/:id', component: ItemPageComponent, data: {section: ''},
  },
  {
    path: '', component: MainPageComponent, data: {section: 'main'},
  }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      scrollPositionRestoration: 'enabled',
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
