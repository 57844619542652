<app-generic-page>
  <div class='content'>
    <div class='tab-selectors'>
      <a class='tab-selector' tabindex="0" (click)='tab = "about"' (keypress)='tab = "about"' [class.active]='tab === "about"'>אודות</a>
      <a class='tab-selector' tabindex="0" (click)='tab = "database"' (keypress)='tab = "database"' [class.active]='tab === "database"'>מה במאגר?</a>
      <a class='tab-selector' tabindex="0" (click)='tab = "coding"' (keypress)='tab = "coding"' [class.active]='tab === "coding"'>שיטת הקידוד</a>
      <a class='tab-selector' tabindex="0" (click)='tab = "team"' (keypress)='tab = "team"' [class.active]='tab === "team"'>צוות המחקר</a>
      <a class='tab-selector' tabindex="0" (click)='tab = "cite"' (keypress)='tab = "cite"' [class.active]='tab === "cite"'>ציטוט המאגר</a>
    </div>
    <div class='tab' *ngIf='tab==="about"'>
      <p class='strong'>
          מהם ארגונים ומוסדות לקידום נשים ושוויון מגדרי?<br/>
          כיצד התפתחו מנגנונים מדינתיים בתחום זה לאורך זמן וכיצד השפיעה התנועה הפמיניסטית על עיצובם?
      </p>
      <p>
          אלו חלק מהשאלות שהניעו את המחקר העומד בבסיס מאגר המידע הנוכחי המתעד את המקרה הישראלי לאורך תקופה של כמעט 50 שנה. המאגר כולל מיפוי של כל המנגנונים והמוסדות לקידום נשים, פמיניזם ושוויון מגדרי שהוקמו ופעלו בישראל בין השנים 2018-1970. במאגר מופיעים ארגונים, גופים ממשלתיים, עמותות או שירותים שהוקמו על ידי נשים או למען קידום זכויות נשים ו/או שהוגדרו באופן מפורש כארגונים פמיניסטיים ו/או שהוקמו במטרה לקדם שוויון מגדרי, כולל להט"ב. 
      </p>
      <p>
          <a routerLink='/database'>בדף החיפוש</a>
           ניתן למיין ולסנן מידע לפי תחומי פעילות, תקופת פעילות, אזורי פעילות, סוג הפעילות, קהל יעד וקריטריונים נוספים. המידע מספק תמונה סטטיסטית רחבה על מגמות היסטוריות בהתפתחותם והתרחבותם של מנגנונים אלו ומיועד לקהל רחב, לחוקרות/ים ולמעצבות/י מדיניות.
      </p>
      <p>
          המידע שעליו מבוסס המאגר נאסף על ידי קבוצת מחקר במשך תקופה של שנתיים מתוך מקורות היסטוריים ועכשוויים הכוללים: פרסומים ארגוניים, מחקרים אקדמיים, עיתונות, מסמכים משפטיים ואתרי אינטרנט. למרות ששיטת איסוף המידע התבססה על 
          <a href='#'><a href='/assets/codebook.pdf'>ספר קידוד</a></a>
           ופרוטוקול מסודר שכלל הצלבת נתונים וקידודם לפי קריטריונים מפורטים, תהליך העיבוד של המידע ההיסטורי והעכשווי התבסס במידה רבה על פרשנות של קבוצת המחקר וייתכן שאינו משקף באופן מדויק את הזיכרון הארגוני או הפרטי של מתנדבות, פעילות ועובדות שהיו מעורבות בארגונים השונים. 
      </p>
      <p>
         מעבר לכך, השונות הרבה בסוגי המנגנונים הנכללים במאגר ובתחומי הפעילות שלהם משקפת לעתים את ההבדלים במשאבים ובגישות של קבוצות שונות בחברה הישראלית לנושאים הנוגעים לקידום מעמד האישה, פמיניזם ושוויון מגדרי. בתהליך עיבוד הנתונים נעשה ניסיון להכיר בשונות הזו על ידי הכללת מגוון רחב של מוסדות ושירותים. בהתאם לכך, לתיאור המנגנונים שהוקמו על ידי נשים ערביות או שהיו מיועדים לנשים ערביות נוסף טקסט בשפה הערבית.
      </p>
      <p class='strong centered'>מנגנונים לקידום נשים, פמיניזם ושוויון מגדרי 2018-1970</p>
        <app-interactive-graph [recordSource]='data.records' [alwaysOpen]='true'>
        </app-interactive-graph>
      <p>
          מאגר הנתונים הינו תוצר של פרויקט מחקר שנערך במסגרת התכנית ללימודי מגדר באוניברסיטת בן-גוריון בנגב ובתמיכת משרד המדע (מענק 66567).
      </p>

      <!-- TODO add inner text links in this tab !-->
    </div>
    <div class='tab' *ngIf='tab==="database"'>
      <h1>אילו מנגנונים כולל המאגר?</h1>

      <p class='strong'>במהדורה הראשונה (2020) של 
        <em>מארג</em>
       מופיעים 593 מנגנונים. "מנגנון" הינו ארגון, גוף ממשלתי או שירות שניתן להגדירו לפחות באחת מהדרכים הבאות:</p>
      <ol>
        <li>הוקם על ידי נשים או למען קידום זכויות נשים</li>
        <li>שהוגדר באופן מפורש כארגון פמיניסטי</li>
        <li>שהוקם למען קידום שוויון מגדרי, כולל להט"ב</li>
      </ol>

      <p class='strong'>במאגר נרשמו סוגים שונים של מנגנונים שהיו פעילים בין השנים 2018-1970 בעלי המאפיינים הבאים:</p>

      <ol>
        <li>מעמד משפטי (עמותה, רשות מוניציפלית, יחידה ממשלתית, חברה, מוסד אקדמי) </li>
        <li>מטרה או חזון שפורסמו בציבור</li>
        <li>צוות של עובדות בשכר או מתנדבות</li>
        <li>מנגנון ניהול כספי</li>
        <li>פעילות מתועדת </li>
      </ol>

      <h1>במאגר נכללים המנגנונים הבאים:</h1>

      <ul>
        <li>מנגנונים ממשלתיים לקידום מעמד האישה ושוויון מגדרי כולל שירותי סיוע </li>
        <li>מנגנונים פרלמנטריים לקידום מעמד האישה ושוויון מגדרי</li>
        <li>מנגנונים מוניציפליים לקידום מעמד האישה ושוויון מגדרי כולל שירותי סיוע</li>
        <li>עמותות רשומות או חברות לתועלת הציבור (חל"צ)</li>
        <li>ועדות ציבוריות שהוקמו מתוקף החלטות ממשלה </li>
        <li>מועצות לאומיות</li>
        <li>פרויקטים לאומיים ותכניות לאומיות</li>
        <li>קואליציות או פורומים של ארגונים בלתי ממשלתיים</li>
        <li>מפלגות ותאים מפלגתיים</li>
        <li>תכניות ללימודי מגדר ותאים סטודנטיאליים</li>
        <li>התארגנויות פמיניסטיות עם פעילות קבועה מעל לשנתיים</li>
        <li>כתבי עת והוצאות ספרים</li>
        <li>סניפים מקומיים של ארגונים בינלאומיים</li>
        <li>מדרשות ומוסדות לימוד דת לנשים</li>
      </ul>

      <p>
      הסבר מפורט לגבי תהליך ושיטת הקידוד ניתן למצוא 
      <a href='/assets/codebook.pdf'>בספר הקידוד</a>.
      </p>

    </div>
    <div class='tab' *ngIf='tab==="coding"'>
      <h1>כיצד קודדו המנגנונים במאגר?</h1>
      <p>כל מנגנון המופיע במאגר קודד על בסיס מידע שנאסף בשנים 2020-2018. המידע מבוסס על מקורות ארגוניים פנימיים (פרסומים, אתרי אינטרנט) ומקורות חיצוניים (מחקרים אקדמיים, עיתונות ומסמכים משפטיים). המנגנונים קודדו על פי קטגוריות קבועות הכוללות מידע אודות תקופת פעילות, אזור פעילות עיקרי, תחומי פעילות, סוג המנגנון, סוג פעילות וקהל יעד. בנוסף, כל מנגנון כולל תיאור קצר עם רקע כללי ועובדתי וכן הפניות למקורות רלוונטיים. מידע נוסף ניתן למצוא <a href='/assets/codebook.pdf'>בספר הקידוד</a>.</p>
      
      <h1 id='years'>תיארוך</h1>
      <div class='nice-list'>
        <div>
          <label>שנת ייסוד</label>
          <span>השנה שבה הוכרז על הקמתו של מנגנון או התחלת הפעילות של קבוצה מייסדת. במידה ונרשמה עמותה בשנה אחרת משנת הייסוד נוספה שנת רישום העמותה. </span>
        </div>
        <div>
          <label>שנת סגירה</label>
          <span>השנה שאחריה אין עדויות לפעילות המנגנון ממקורות חיצוניים, או לחילופין, השנה שבה ארגון פורק באופן רשמי. מנגנונים שהיו פעילים בשנת 2018 קודדו בהתאם. </span>
        </div>
      </div>

      <h1 id='activity_area'>אזור פעילות</h1>
      <p>הכתובת הרשמית ואזור הפעילות העיקרי נקבעו לפי שנת הפעילות האחרונה, בהתאם לחלוקה של משרד הפנים והלשכה המרכזית לסטטיסטיקה: </p>
      <p class='centered'>ארצי | הדרום | המרכז | הצפון | חיפה | ירושלים | יהודה ושומרון</p>

      <h1 id='fields_of_activity'>תחומי פעילות</h1>
      <p>בהסתמך על ניתוח תוכן של מידע אודות כלל המנגנונים, יצרנו רשימת תחומים ותתי תחומים. לכל מנגנון הוגדרו עד שלושה תחומי פעילות עיקריים. קידוד התחום העיקרי של כל מנגנון נקבע על פי תחום הפעילות המרכזי שלו כפי שהוצג במסמכים רשמיים. עבור כל תחום הוגדרו מספר תתי-תחומים בהתאם לרשימה הבאה:</p>

      <div class='nice-list'>
        <div>
          <label>אלימות</label>
          <span>אלימות מינית ומגדרית; אלימות במשפחה; הטרדה מינית</span>
        </div>
        <div>
          <label>בריאות ופריון</label>
          <span>בריאות הנפש וטיפול; תכנון משפחה וחינוך מיני; תמיכה נפשית; הריון ולידה; טכנולוגיות פריון; בריאות האישה; בריאות ומגדר; מוגבלות</span>
        </div>
        <div>
          <label>דת ומעמד אישי</label>
          <span>השכלה; מעמד אישי ודיני משפחה; נערות וצעירות; תעסוקה ועבודה; תרבות ואמנות; פוליטיקה ומנהיגות; להט"ב; בריאות ופריון </span>
        </div>
        <div>
          <label>הגירה</label>
          <span>קליטת עלייה; הגירת עבודה; פליטות ומבקשות מקלט; סחר בבני אדם</span>
        </div>
        <div>
          <label>השכלה גבוהה ומחקר</label>
          <span>תכניות לתואר אקדמי בלימודי מגדר; תאים סטודנטיאליים; קידום נשים במדע; אקדמיה, חברה וקהילה; מרכזי מחקר; אגודות מקצועיות; כתבי עת</span>
        </div>
        <div>
          <label>חינוך</label>
          <span> חינוך מיני/מיניות בריאה; מניעת אלימות; להט"ב; פמיניזם ושוויון בין המינים; הגיל הרך; צמצום פערים בחינוך; מדע וטכנולוגיה; כללי </span>
        </div>
        <div>
          <label>להט"ב</label>
          <span>משפחה; דת; תרבות וספורט; פוליטיקה ומנהיגות; ארגונים קהילתיים; חינוך והשכלה; רווחה ובריאות; זכויות</span>
        </div>
        <div>
          <label>נערות וצעירות</label>
          <span> להט"ב; בריאות; מדע וטכנולוגיה; צעירות במצבי סיכון; זכויות; מנהיגות; דת; חינוך</span>
        </div>
        <div>
          <label>צבא, ביטחון ומדיניות חוץ</label>
          <span> קידום נשים ושוויון מגדרי; התאגדויות; דיפלומטיה ויחסי חוץ; ביטחון לאומי; ביטחון אישי</span>
        </div>
        <div>
          <label>פוליטיקה ומנהיגות</label>
          <span>מפלגות, שדולות ותאים מפלגתיים; ועדות פרלמנטריות וממשלתיות; פורומים ומועצות נשים; קידום מנהיגות נשים; ייצוג הולם; תאים סטודנטיאליים; כללי</span>
        </div>
        <div>
          <label>רב-תחומי </label>
          <span>קידום נשים; פמיניזם; שוויון בין המינים/שוויון מגדרי</span>
        </div>
        <div>
          <label>קידום נשים, פמיניזם או הטמעה מגדרית</label>
          <span>אין תתי תחומים</span>
        </div>
        <div>
          <label>רווחה וקהילה</label>
          <span>נערות וצעירות; התמכרויות ושיקום; אמהות חד הוריות; זנות וסחר בנשים; אלימות במשפחה; להט"ב; תעסוקה ועבודה; אלימות מינית</span>
        </div>
        <div>
          <label>שלום</label>
          <span>אין תתי תחומים</span>
        </div>
        <div>
          <label>תעסוקה ועבודה</label>
          <span>הכשרה מקצועית; התאגדויות ורשתות מקצועיות; השמה וליווי בתעסוקה; העצמה כלכלית; זכויות ומדיניות; מעונות ומשפחתונים; יזמות עסקית ועצמאיות</span>
        </div>
        <div>
          <label>תעשיית המין</label>
          <span>זנות; סחר בנשים; פורנוגרפיה</span>
        </div>
        <div>
          <label>תקשורת</label>
          <span>אין תתי תחומים</span>
        </div>
        <div>
          <label>תרבות וספורט</label>
          <span>אמנות; מוזיקה; מחול; ספורט; פנאי; כתבי עת והוצאה לאור; קולנוע; תיאטרון; מלאכות מסורתיות</span>
        </div>
      </div>

      <h1 id='organization_kind'>סוג ארגון</h1>
      <p>כל מנגנון קודד בהתאם לסיווג שלהלן. במקרים שבהם המנגנון נוצר או מתקיים כתוצאה של שיתוף פעולה בין סקטורים, נוסף גם סיווג משני.</p>
      <ol>
        <li>מדינתי</li>
        <li>מוניציפלי</li>
        <li>אקדמי </li>
        <li>מפלגתי</li>
        <li>בינלאומי </li>
        <li>קואליציה, איגוד או פורום</li>
        <li>עמותה רשומה, חל"צ או אגודה עותומנית</li>
        <li>התארגנות שאינה עמותה (כולל ארגוני פילנתרופיה) </li>
        <li>מוסד תורני</li>
      </ol>

      <h1 id='activity_kind'>סוג פעילות</h1>
      <p>על מנת להבין את ההתפתחויות בסוגי הפעילות של ארגונים לאורך זמן, סוג הפעילות העיקרי קודד על פי דפוס הפעילות בשנת ההקמה. עבור כל מנגנון קודד סוג פעילות עיקרי ושני סוגי פעילות משניים על פי הסיווג הבא:</p>

      <div class='nice-list'>
        <div>
          <label>אקטיביזם רשת </label>
          <span>פעילות שעיקרה נעשית ברשתות חברתיות</span>
        </div>
        <div>
          <label>הפעלת מעונות יום </label>
          <span>מעונות יום שהוקמו במטרה לסייע לאמהות עובדות</span>
        </div>
        <div>
          <label>חינוך, הכשרה והעלאת מודעות </label>
          <span>פעילות שעיקרה הוראה, סדנאות, כנסים, פרסומים, קורסים, קבוצות או קמפיינים</span>
        </div>
        <div>
          <label>ייעוץ, שירות וסיוע</label>
          <span>פעילות המכוונת למתן שירותי ייעוץ, תמיכה וסיוע בכל תחום, כולל מתן מידע וסיוע חומרי, הכוונה פרטנית, טיפול נפשי בין היתר דרך מסגרות טיפוליות ו/או באמצעות קווי סיוע </span>
        </div>
        <div>
          <label>מדיניות ציבורית </label>
          <span>תכנון, קביעת מדיניות ויישומה על ידי גופים ממשלתיים ומוניציפליים באמצעות חקיקה, ועדות ממשלתיות, פיתוח תכניות, רגולציה, הקצאת משאבים ודיווח לגורמים בינלאומיים</span>
        </div>
        <div>
          <label>מחאה </label>
          <span>פעילות עיקרית הכוללת ארגון של משמרות, צעדות והפגנות</span>
        </div>
        <div>
          <label>מחקר ותיעוד </label>
          <span>מחקר אקדמי ויישומי הכולל איסוף ופרסום נתונים, מאמרים ודוחות</span>
        </div>
        <div>
          <label>סנגור, מעקב ושינוי מדיניות </label>
          <span>פעילות משפטית, הובלת חקיקה ופעולה אל מול גופים ממשלתיים ומוניציפליים במטרה להשפיע על מדיניות, על ידי ארגונים בלתי ממשלתיים או התנדבותיים </span>
        </div>
        <div>
          <label>עבודה קהילתית </label>
          <span>פעילות המאורגנת עם ו/או על ידי קבוצות נשים ולהט"ב בקהילות מקומיות ומכוונת לצרכי הקהילה, כולל מועדוני נשים ואירועי תרבות ופנאי</span>
        </div>
        <div>
          <label>פילנתרופיה</label>
          <span>קרנות או ארגונים שעיקר פעילותם כוללת תמיכה כספית בפרויקטים, תרומות, מענקים ומלגות (כולל סניפים של ארגונים בינלאומיים)</span>
        </div>
        <div>
          <label>שירותים משפטיים</label>
          <span>סיוע משפטי, ליווי בהליך פלילי או ייעוץ משפטי פרטני או קבוצתי</span>
        </div>
      </div>

      <h1 id='target_audiences'>קהל יעד</h1>

      <p>סיווג המתייחס אל הקבוצה המייסדת או אל קהלי היעד שאליהם מכוונת פעילות המנגנון. קהל היעד העיקרי של מרבית המנגנונים קודד כ"נשים (כללי)". במידה והארגון משרת מגוון קבוצות, קודדו קהלי יעד נוספים, עד ארבע קבוצות לפי הסיווג שלהלן:</p>

      <ol>
        <li>גברים</li>
        <li>נשים דתיות</li>
        <li>נשים חרדיות</li>
        <li>נשים יוצאות אתיופיה</li>
        <li>להט"ב</li>
        <li>נשים מהגרות עבודה או חסרות מעמד</li>
        <li>נשים מזרחיות</li>
        <li>נשים עולות מחבר העמים</li>
        <li>נשים ערביות (כולל בדואיות ודרוזיות)</li>
      </ol>

      <h1 id='description'>תיאור</h1>

      <p>תיאור המנגנונים נועד לספק רקע קצר וכללי על כל מנגנון במאגר, כולל מידע על תקופת ונסיבות הקמתו, על פעילותו העיקרית (והאופן שבו השתנתה פעילות זו עם השנים במידה ורלוונטי), על דפוסי הפעלתו והשינויים שחלו בהם (במידה ואלו התרחשו) ועל הקשר של המנגנון למנגנונים אחרים. תיאור המנגנון מבוסס על הפרשנות של צוות המחקר למידע שנאסף ממקורות חיצוניים  וייתכן שאינו משקף באופן מדויק את הזיכרון הארגוני או הפרטי של מתנדבות, פעילות ועובדות שהיו מעורבות בארגונים השונים. התיאור הינו מתומצת ואינו מתיימר לספק תיעוד היסטורי מקיף של כלל הפעילויות של כל אחד מהמנגנונים המופיעים במאגר. במידת הצורך, עדכונים ותיקונים של תיאורי המנגנונים יופיעו בגרסאות עתידיות של מאגר הנתונים. מועדי היצירה והעדכון האחרון של כל ערך מצויינים בתחתית דף הערך ומאפשרים מעקב אחר שינויים אלו.</p>

      <h1 id='sources'>מקורות</h1>

      <p>עבור כל מנגנון נוספו הפניות למקורות שעליהם התבסס קידוד הנתונים והמידע. במקרים שבהם המקורות נמצאים ברשת האינטרנט, ישנה הפניה לאתר.* במקרים אחרים, מצויין מקום הימצאו של המקור בהתאם לכללי הציטוט האקדמי (פרק בספר, ארכיונים של ארגונים, ארכיון פרטי של החוקרות, ועוד). במקרים שבהם התבססנו על מקורות בעל פה (ראיונות ושיחות עם פעילות ועובדות), צוינו תפקיד המרואיינת ומועד השיחה עמה.</p>
      <p>* ההפנייה לאתרי אינטרנט משקפת את המידע הקיים בעת יצירת הערך או עדכונו. חלק מהערכים כוללים קישורים לאתרי אינטרנט חיצוניים שעלולים להשתנות או להיעלם עם הזמן.</p>

    </div>
    <div class='tab' *ngIf='tab==="team"'>

      <div class='nice-list'>
        <div>
          <label class='bold'>ד"ר שרי אהרוני</label>
          <span class='large'>מרצה בתכנית ללימודי מגדר באוניברסיטת בן גוריון בנגב. חוקרת, בין היתר, את ההיסטוריה המוסדית של התנועה הפמיניסטית בישראל ובעולם ואת דפוסי ההתפתחות של מנגנונים מוסדיים לקידום נשים.</span>
        </div>
        <div>
          <label class='bold'>ד"ר יעל חסון </label>
          <span class='large'>סוציולוגית העוסקת במגדר, פמיניזם, מדיניות כלכלית-חברתית ואי-שוויון. חוקרת בין היתר את התפתחותם של מנגנונים פמיניסטיים ולשוויון מגדרי בישראל. מנהלת המחקר בתחום מגדר במרכז אדוה ועמיתת פוסט-דוקטורט באוניברסיטת בן גוריון בנגב.</span>
        </div>
        <div>
          <label class='bold'>יעל בר-צדק</label>
          <span class='large'>דוקטורנטית ומרצה לשיטות מחקר איכותניות ופמיניסטיות בתכנית ללימודי מגדר בר-אילן. מחקר הדוקטורט שלה עוסק במשמעויות החברתיות של החוק למניעת הטרדה מינית. </span>
        </div>
        <div>
          <label class='bold'>ליהי רוטשילד</label>
          <span class='large'>דוקטורנטית לסוציולוגיה ב- Manchester Metropolitan University. מחקר הדוקטורט שלה עוסק בהיבטים חברתיים, מגדריים ומשפטיים של מבנים משפחתיים ואינטימיים החורגים ממודל הזוגיות הנורמטיבית בקהילות קוויריות ופוליאמוריות בישראל.</span>
        </div>
        <div>
          <label class='bold'>שגיא וטמברג-ליוש</label>
          <span class='large'>בוגרת תואר ראשון בפילוסופיה, כלכלה ומדעי המדינה מהאוניברסיטה העברית בירושלים ולומדת לתואר שני בלימודי משפט בבר-אילן. עבדה באגף לקידום נשים בנציבות שירות המדינה.</span>
        </div>
        <div>
          <label class='bold'>יסמין אבו סאלח</label>
          <span class='large'>בוגרת תואר ראשון בפסיכולוגיה מאוניברסיטת חיפה, סטודנטית לתואר שני בעבודה סוציאלית במכללת תל חי. מתגוררת ברמת הגולן.</span>
        </div>
        <div>
          <label class='bold'>אח'לס סקיק</label>
          <span class='large'>בוגרת תואר ראשון בלשון עברית והיסטוריה כללית מאוניברסיטת בן גוריון בנגב. במקור מכפר ביר אלמשאש, כפר בדואי בלתי מוכר. מתגוררת בכפר אבו קרינאת. אמא לאחמד וצלמת אירועים.</span>
        </div>
        <div>
          <label class='bold'>מיכל זאבי </label>
          <span class='large'>מומחית בתחום מערכות המידע ודוקטורנטית בביה״ס למדעי התרבות באוניברסיטת תל אביב. תחום המחקר המרכזי שלה הוא אקטיביזם פמיניסטי ברשת ותרומתו לקידום שוויון מגדרי בישראל.  </span>
        </div>
      </div>

      <p>תודה גם למייס אבו-קנדיל ולעינת גלבוע-אופנהיים</p>
    </div>
    <div class='tab' *ngIf='tab==="cite"'>

      <h1>כיצד לצטט נתונים מהמאגר?</h1>

      <span class='cite'>
      אהרוני, שרי ויעל חסון (2020). 
      <strong>מארג: מאגר מנגנונים ומוסדות לקידום נשים, פמיניזם ומגדר בישראל 2018-1970.</strong>
       מאגר מידע (גרסה 1.0). אוניברסיטת בן גוריון בנגב.
      </span>
      <span class='cite ltr'>
      Aharoni Sarai B. and Yael Hasson (2020). Women's Policy Agencies and State Feminism in Israel 1970-2018 (WPA-IL). Dataset version 1.0. [Hebrew]. Ben-Gurion University of the Negev.
      </span>
      <p>כל הזכויות שמורות למחברות © 2020</p>
    
    </div>
  </div>
</app-generic-page>