import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filters-placeholder-input',
  templateUrl: './filters-placeholder-input.component.html',
  styleUrls: ['./filters-placeholder-input.component.less']
})
export class FiltersPlaceholderInputComponent implements OnInit {

  @Input() placeholder: string;
  @Input() active: boolean;
  @Output() clear = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

}
