<app-generic-page>
  <div class='content'>
    <div class='header'>
      Women's Policy Agencies, Feminist Organizations and Mechanisms for Gender Equality in Israel 1970-2018
    </div>
    <div class='text'>
      <p>The Israel Women’s Policy Agencies (WPA-IL) dataset includes information of all governmental and non-governmental mechanisms to promote women’s equality, feminism, gender and LGBT rights, which were established in Israel from 1970 to 2018. </p>
      <p>The dataset comprises of state policy agencies and committees, municipal units, political parties, services, non-governmental organizations, civil society coalitions, academic programs, and religious institutions. In 2020 the first version of the dataset became accessible to the public. This version, which includes 593 organizations, is mostly in the Hebrew language, with additional information in Arabic when necessary. </p>
      <p>The data was gathered in 2018-2020 by a research group from Ben-Gurion University of the Negev, headed by Dr. Sarai B. Aharoni and Dr. Yael Hasson from the Gender Studies Program. The information on each mechanism was based on various types of historical and contemporary documents including internal sources (organizational publications or websites) and external sources (archives, academic studies, press coverage and legal documents). Each mechanism was coded into the dataset according to: (1) <b>Period of activity</b>; (2) <b>Location</b>; (3) <b>Main field of activity</b> e.g., violence, health and fertility, politics, peace, etc. and sub-fields.; (4) <b>Sector</b> e.g., governmental, municipal, NGO; (5) <b>Type of activity</b> e.g., education and awareness raising, public policy, protest, etc.; (5) Target audience including women, men, LGBT, Arab women, Ethiopian women, religious women, ultra-Orthodox women, etc. For each mechanism a brief description and factual background was included as well as a list of references.</p>
      <p>The current version of the dataset includes a search option (in Hebrew) in order to sort and filter information by types of activity, period, location, field and target audience. The information provides a broad statistical representation of historical trends and of the development and expansion of mechanisms and institutions to promote women, feminism and gender equality in Israel in the last 50 years.</p>

      <p>For more information, please contact us at: <a href='mailto:wpaisrael@gmail.com' target='_blank'>wpaisrael@gmail.com</a></p>

      <p class='strong'>How to cite the database?</p>
      <p>Aharoni Sarai B. and Yael Hasson (2020). Women's Policy Agencies and State Feminism in Israel 1970-2018 (WPA-IL). Dataset version 1.0. [Hebrew]. Ben-Gurion University of the Negev</p>

      <p>The project was funded by the Israeli Ministry of Science (MOST) grant 66567</p>
      <p>© all rights reserved to the authors, 2020  </p>
    </div>
  </div>>
</app-generic-page>
