import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  popup = new BehaviorSubject<string>(null);

  constructor() { }

  close(whom?) {
    if (this.popup.getValue() && (!whom || whom === this.popup.getValue())) {
      this.popup.next(null);
    }
  }

  open(whom) {
    if (whom !== this.popup.getValue()) {
      this.popup.next(whom);
    }
  }
}
