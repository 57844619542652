<app-generic-page>
  <div class='content'>
    <h1>חיפוש במאגר (בהרצה)</h1>

    <app-filters></app-filters>

    <ng-container *ngIf='data.has_results'>

      <h2 *ngIf='records.length > 0'>כמות מנגנונים פעילים לאורך זמן</h2>

      <app-interactive-graph [recordSource]='data.filtered' *ngIf='(data.filtered | async).length'>
      </app-interactive-graph>

      <h2>נמצאו {{ records.length }} תוצאות</h2>

      <div class='sort-by' *ngIf='(data.filtered | async).length'>
        <h3>להציג לפי שנת ייסוד</h3>
        <app-toggle-switch [active]='data._sort_by === null' (left)='data.sort_by = null' (right)='data.sort_by = "year_founded"'></app-toggle-switch>
        <h3>להציג לפי א-ב</h3>
        <span>(לחצו למידע נוסף)</span>
      </div>

      <div class='results' *ngIf='(data.filtered | async).length'>
        <app-search-result *ngFor='let item of records' [item]='item'></app-search-result>
      </div>
    </ng-container>
  </div>

</app-generic-page>