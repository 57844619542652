<app-generic-page>
  <div class='content'>
    <div class='header'>
      وكالات السياسات النسائية، المنظمات النسوية وآليات المساواة بين الجنسين في إسرائيل 1970-2018
    </div>
    <div class='text'>
      <p>تُعتَبر وكالة السياسيات النسوية في إسرائيل (WPA-IL) سِجَّل المعلومات الذي يحتوي على جميع الآليات العمل الحكومية وغير الحكومية لتعزيز مساواة المرأة، النسوية، الجنس، وحقوق المثليين، والتي تم إنشاؤها في إسرائيل بين أعوام 1970 وحتى 2018.</p>
      <p>تتكون مجموعة البيانات من وكالات ولجان حكومية ومحلية الى جانب أعمال الأحزاب السياسية، المنظمات المجتمعية وجمعيات غير حكومية، ائتلافات المجتمع المدني والبرامج الأكاديمية والمؤسسات الدينية. </p>
      <p>في عام 2020، انطلقت النسخة الأولى من مجموعة البيانات المتاحة للجمهور. هذه النسخة، التي تضم 593 منظمة، هي في الغالب باللغة العبرية، وبعضها باللغة العربية عند الضرورة.  بحيث تم جمع البيانات بين أعوام 2018-2020 من قبل مجموعة بحثية من جامعة بن غوريون في النقب، برئاسة د. ساراي ب. أهاروني ود. ياعيل حسون من قسم الدراسات النسوية في الجامعة. </p>
      <p>استندت المعلومات المتعلقة بكل آلية إلى أنواع مختلفة من الوثائق التاريخية والمعاصرة بما في ذلك المصادر الداخلية (المنشورات التنظيمية أو المواقع الإلكترونية) والمصادر الخارجية (المحفوظات، الدراسات الأكاديمية، التغطية الصحفية والوثائق القانونية). وتم درج كل آلية في مجموعة البيانات وفقًا لما يلي: (1) فترة النشاط؛ (2) مكان النشاط؛ (3) المجال الرئيسي للنشاط مثل: العنف، الصحة، السياسة، السلام وما إلى ذلك  كما والى مجالات الفرعية؛ (4) القطاع، على سبيل المثال: حكومي، بلدي، المنظمات غير الحكومية؛ (5) نوع النشاط، على سبيل المثال: التعليم والتوعية، السياسة العامة، المعارضة والاحتجاج  وما إلى ذلك؛ (5) جمهور الهدف بما في ذلك: النساءالرجال، والمثليات والمثليين ومزدوجي الميول الجنسي، مغايري الهوية الجنسية، النساء العربيات والإثيوبيات والمتدينات والنساء الأرثوذكسيات والخ. لكل آلية تم تضمين وصف موجز وخلفية وقائعية بالإضافة إلى قائمة المراجع.</p>
      <p>يتضمن الإصدار الحالي من مجموعة البيانات خيار البحث (بالعبرية) من أجل فرز المعلومات وتصفيتها حسب أنواع النشاط: الفترة، المكان، المجال وجمهور الهدف. توفر المعلومات تمثيلًا إحصائيًا واسعًا للاتجاهات التاريخية وتطوير وتوسيع الآليات والمؤسسات لتعزيز المرأة والنسوية والمساواة بين الجنسين في إسرائيل في الخمسين عامًا الماضية.</p>

      <p>لمزيد من المعلومات، يرجى التواصل معنا من خلال: <a href='mailto:wpaisrael@gmail.com' target='_blank'>wpaisrael@gmail.com</a></p>

      <p class='strong'>كيفية الوصول لقاعدة البيانات؟</p>
      <p class='ltr'>Aharoni Sarai B. and Yael Hasson (2020). Women's Policy Agencies and State Feminism in Israel 1970-2018 (WPA-IL). Dataset version 1.0. [Hebrew]. Ben-Gurion University of the Negev.</p>

      <p>تم تمويل المشروع من قبل وزارة العلوم الإسرائيلية (MOST) منحة 66567</p>
      <p>© جميع الحقوق محفوظة للمؤلفين 2020</p>
    </div>
  </div>>
</app-generic-page>
