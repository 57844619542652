import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PopupService } from '../popup.service';

@Component({
  selector: 'app-filters-dropdown-list-selector',
  templateUrl: './filters-dropdown-list-selector.component.html',
  styleUrls: ['./filters-dropdown-list-selector.component.less']
})
export class FiltersDropdownListSelectorComponent implements OnInit {

  @Input() data: string[] | any[] = [];
  @Input() textAlign = 'center';
  @Input() empty = false;
  @Input() active = false;
  @Input() slug = '';
  @Output() selected = new EventEmitter<string>();

  constructor(private popup: PopupService) {
    this.popup.popup.subscribe((open) => {
      this.active = open === this.slug;
    });
  }

  ngOnInit() {
  }

  valueOf(item) {
    return typeof item === 'string' ? item : item.value;
  }

  showOf(item) {
    return typeof item === 'string' ? item : item.show;
  }

  select(value) {
    this.selected.next(value);
    this.popup.close();
  }

}
