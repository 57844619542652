<app-generic-input slug='theme_selector' [disabled]='disabled'>
    <app-filters-placeholder-dropdown class='placeholder'>
        <ng-container *ngIf='!placeholder'>
            <span>תחום פעילות</span>
            <span class='small'>(עיקרי ומשני)</span>
        </ng-container>
        <ng-container *ngIf='placeholder'>
            <span [innerHTML]='placeholder'></span>
            <span *ngIf='placeholder' class='clear'
                  (click)='clear($event)'>&times;</span>
        </ng-container>
    </app-filters-placeholder-dropdown>
    <app-filters-dropdown-theme-selector [slug]='"theme_selector"' class='popup' (selected)='selected($event)'></app-filters-dropdown-theme-selector>
</app-generic-input>