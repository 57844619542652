import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../data.service';
import { PRIMARY_FIELD_OF_ACTIVITY } from '../data';
import { PopupService } from '../popup.service';

@Component({
  selector: 'app-filters-subject-selector',
  templateUrl: './filters-subject-selector.component.html',
  styleUrls: ['./filters-subject-selector.component.less']
})
export class FiltersSubjectSelectorComponent implements OnInit {

  filtered: any[];

  @Input() disabled = false;

  constructor(public data: DataService, public popup: PopupService) {
    this.popup.popup.subscribe((open) => {
      if (open !== 'subject_selector') {
        this.filter('');
      }
    });
  }

  ngOnInit() {
    this.filter('');
  }

  filter(value) {
    this.filtered = [];
    for (const item of PRIMARY_FIELD_OF_ACTIVITY) {
      if (!value || item.indexOf(value) !== -1) {
        this.filtered.push({
          show: item.replace(value, '<b>' + value + '</b>'),
          value: item
        });
      }
    }
  }

  select(value) {
    this.data.filters.subject = value;
  }
}
